'use strict';

var _ = require('lodash');
var ajax = require('../../ajax');
var dialog = require('../../dialog');
var GoogleTagManagerClient = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/util/GoogleTagManagerClient');
var minicart = require('../../minicart');
var page = require('../../page');
var SiteConstants = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');
var TPromise = require('promise');
var util = require('../../util');

var dataBuffer = {};

/**
 * Wraps the dialog close functionality in a function so that we can hook into the transitionEnd event
 * to fire the close event on the dialog.
 * This allows for an animation to complete before the dialog is closed and removed from the dom
 */
 var closeAddToCartModal = function () {
	// save overaly for class additions
	var $overlay = $('.add-to-cart-modal-wrapper').parent().find('.ui-widget-overlay');
	
	// hook into transition end
	if (pageContext.site === SiteConstants.SiteIds.BootBarnRspUS) {
		var currentModal = $('#dialog-container');
		if (currentModal.dialog('isOpen')) {
			currentModal.dialog('close');
		}
	} else {
		$('.add-to-cart-modal-wrapper').one('transitionend webkitTransitionEnd oTransitionEnd', function (e) {
			var currentModal = $(this).find('#dialog-container');
			if (currentModal.dialog('isOpen')) {
				currentModal.dialog('close');
			}
		});
	}
	
	$overlay.removeClass('show');
	$('.add-to-cart-modal-wrapper').removeClass('show');
}

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
var addItemToCart = function (form) {
    var $form = $(form),
        $qty = $form.find('input[name="Quantity"]');
    if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
        $qty.val('1');
    }
    return TPromise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.addProduct),
        data: $form.serialize()
    }));
};

/**
 * @description Handler to handle the add to cart event
 */
var addToCart = function(event, data) {
    event.preventDefault();
	var $html = $('html');
	var $container = $('#wrapper');
    var $form = $('.pdpForm');
    var sizeSelected = $('div.product-variations .size.size-options li.selected');
	var lengthSelected = $('div.product-variations .length.length-options li.selected').length;
	var widthSelected = $('div.product-variations .width.width-options li.selected').length;
	var isSizeSelected = sizeSelected.length || (lengthSelected && widthSelected);

    if (!isSizeSelected) {
    	dialog.open({
			html: 'Please select a size',
			options: {
				dialogClass: 'dialog-alert',
				draggable: false
			}
		});
    	return false;
    }

    if (!$('div.product-variations .color.color-options li.selected').length) {
    	dialog.open({
			html: 'Please select a color',
			options: {
				dialogClass: 'dialog-alert',
				draggable: false
			}
		});
    	return false;
    }

    var isBopis = false;
	var $bopisAddToCartOptions = $('.bopis-add-to-cart-options-container');
	var $bopisAddToCartOption;
	if ($bopisAddToCartOptions.length > 0) {
		if (pageContext.site === SiteConstants.SiteIds.BootBarnRspUS) {
			$bopisAddToCartOptions.find('input[name="AddToCartOption"]').prop('checked', false);
			$(event.currentTarget).closest('li').find('input[name="AddToCartOption"]').prop('checked', true);
			$bopisAddToCartOptions = $('.bopis-add-to-cart-options-container');
		}
		$bopisAddToCartOption = $bopisAddToCartOptions.find('input[name="AddToCartOption"]:checked');
		var $bopisAddToCartLabel = $bopisAddToCartOption.closest('li');
		var $bopisOptionalMessage = $bopisAddToCartLabel.find('.add-to-cart-optional-message');
		if ($bopisOptionalMessage.length > 0) {
			dataBuffer['OptionalMessage'] = $bopisOptionalMessage.first().html().trim();
		}
	}

    if ($(event.currentTarget).hasClass('bopis') || (data && data.bopis)) {
    	isBopis = true;
    } else if ($bopisAddToCartOptions.length > 0) {
		var bopisShippingOption = $bopisAddToCartOption.val();
		if (bopisShippingOption == 'delivery') {
			$(document).trigger('Bopis.LocalDelivery.Display', { OptionalMessage: dataBuffer['OptionalMessage'] });
			return false;
		} else if (bopisShippingOption == 'shiptostorepickup') {
			isBopis = true;
			data = data || {};
			data.bopisStore = $bopisAddToCartOptions.find('.selected-store[store-id]').attr('store-id');
			data.shippingOption = bopisShippingOption;
		} else if (bopisShippingOption == 'pickup') {
			isBopis = true;
			var storeData;
			var $storeData = $bopisAddToCartOptions.find('.selected-store[store-data]');
			if ($storeData.length > 0) {
				storeData = JSON.parse($storeData.attr('store-data'))
				data = data || {};
				data.bopisStore = storeData.location.code;
				data.quantityAvailable = storeData.detail.quantity;
				data.shippingOption = bopisShippingOption;
			}
		}
	}

    var $bopisStore;
    if (isBopis) {
    	$bopisStore = $('input[name="Bopis_StoreSearch_StoreId"]:checked');
		$('.product-add-to-cart input[name="bopis_store"]').val(data.bopisStore ? data.bopisStore : $bopisStore.val());
    	$('.product-add-to-cart input[name="bopis_quantity_available"]').val(data.quantityAvailable != undefined ? data.quantityAvailable : $bopisStore.attr('quantity-available'));
    	$('.product-add-to-cart input[name="bopis_shipping_option"]').val(data.shippingOption ? data.shippingOption : '');
    	$('.product-add-to-cart input[name="bopis_delivery_address"]').val(data.deliveryAddress ? data.deliveryAddress : '');
    	$('.product-add-to-cart input[name="bopis_delivery_note"]').val(data.deliveryNote ? data.deliveryNote : '');
    	$('.product-add-to-cart input[name="bopis_delivery_gift_message"]').val(data.giftMessage ? data.giftMessage : '');
    	$('.option-dropdown select[name*="_Bopis"]').val('Yes');
    } else {
    	$('.product-add-to-cart input[name="bopis_store"]').val('');
    	$('.product-add-to-cart input[name="bopis_quantity_available"]').val('');
    	$('.product-add-to-cart input[name="bopis_shipping_option"]').val('');
    	$('.product-add-to-cart input[name="bopis_delivery_address"]').val('');
    	$('.option-dropdown select[name*="_Bopis"]').val('No');
	}

	$('.product-add-to-cart input[name="channel"]').val(data && data.channel ? data.channel : '');
	if (data && 'csrf_token' in data && data.csrf_token) {
		$('#csrftoken').val(data.csrf_token);
	}
    
    if (sizeSelected.hasClass("tempselection")) {
    	sizeSelected.addClass('enterSelected');
    	sizeSelected.trigger('click');
    	sizeSelected.find('.swatchanchor').trigger('click');
    	setTimeout( function(){$("#add-to-cart").click()}, 100 );
    	return false;
    }

    if (typeof dataLayer !== 'undefined') {
		var qty = $('.pt_product-details .pdpForm .product-add-to-cart select[id=Quantity]').val();
		var variationAttr = $('.product-variations').data('attributes');
		var sizeAttrValue = variationAttr.size.displayValue;
		if (sizeAttrValue == '' || sizeAttrValue == null ) {
			if (sizeSelected.hasClass("tempselection") && sizeSelected.find('a.swatchanchor').data() != null ) {
				sizeSelected.addClass('enterSelected');
				var sizeObjRef = sizeSelected.find('a.swatchanchor').data();
				sizeAttrValue = sizeObjRef.sizeName;
				sizeSelected.find('a.swatchanchor').trigger('click');
			}
		}

		GoogleTagManagerClient.PushDataLayerForDataLayerType('AddToCart');

		for (var i=0; i < dataLayer.length; i++) {
			if (dataLayer[i].event == 'pdpDisplay') {
				var selectedItem = dataLayer[i].ecommerce.detail.products[0];
				var itemPrice = (selectedItem.silhouette == 'Gift Card') ?  parseInt((variationAttr.size.value).replace('$','')) : selectedItem.price;
				var skuID = $('#pid').val();
				var image = $('.product-primary-image [class*="-slide"]').first().find('a').attr('href');
				if (!image || image == undefined || image == null) {
					image = $('.product-primary-image [class*="-slide"]').first().find('img').attr('src');
				}

				if (SitePreferences.CORDIAL_ENABLED) {				
					var cart_data = [{
					    'productID': selectedItem.id,
					    'sku': skuID,
					    'category': (selectedItem.category).replace(/\\/g, ""),
					    'name': (selectedItem.name).replace(/\\/g, ""),
					    'qty': qty, 
					    'itemPrice': itemPrice,
					    'image': image,
					    'url':$(location).attr("href").split("?utm")[0].split("#utm")[0].split("&utm")[0],
					    'attr': {
					        'manufacturer': selectedItem.brand,
					    	'color': variationAttr.color.displayValue,
					        'size': variationAttr.size.displayValue
					    }
					}];
	
					if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
						crdl([
							['cart', 'update'],
							['cartitem', 'add' , cart_data], 
							['event', 'cart']
						]);
					}
				}
				
				var dateTime = Math.floor(Date.now() / 1000);
				var eventID = 'addtocart-' + selectedItem.id + '-' + dateTime;
				
				if (pageContext.site === SiteConstants.SiteIds.BootBarnUS && SitePreferences.PINTEREST_ENABLED) {
					pintrk('track', 'addtocart', {
						event_id: eventID,
						value: itemPrice * qty,
						order_quantity: qty,
						currency: $('meta[itemprop="priceCurrency"]').attr('content'),
						line_items: [
							{
								product_name: (selectedItem.name).replace(/\\/g, ""),
								product_id: selectedItem.id,
								product_category: (selectedItem.category).replace(/\\/g, ""),
								product_variant_id: skuID,
								product_price: itemPrice,
								product_quantity: qty,
								product_brand: selectedItem.brand
							}
						]
					});
				}
			}
		}
    }

    addItemToCart($form).then(function (response) {
    	$.ajax({url : Urls.cartItemCount, method: 'POST', dataType: 'json', success : function(data) {
			if (data && data.ProductCount != undefined) {
    			$('.btn-minicart span').html(Math.round(data.ProductCount));
			}
    	}});
        var $uuid = $form.find('input[name="uuid"]');
        if ($uuid.length > 0 && $uuid.val().length > 0) {
            page.refresh();
        } else if(SitePreferences.REDIRECT_TO_CART && (!pageContext.options || pageContext.options.StoreUIVersion != '2024Redesign')){
    		var redirectUrl = (SitePreferences.HIDE_BASKET) ? Urls.onePage : Urls.cartShow;
    		window.location.href = redirectUrl;
    	} else {
			if (SitePreferences.ADD_TO_CART_MODAL || (pageContext.options && pageContext.options.StoreUIVersion == '2024Redesign')) {
				var qtyAdded = $('.pt_product-details .pdpForm .product-add-to-cart select[id=Quantity]').val();
				$.ajax({
					url: Urls.addToCartModalDisplay,
					data: {
						storeuiversion: pageContext && pageContext.options && pageContext.options.StoreUIVersion ? pageContext.options.StoreUIVersion : null,
						shippingoption: bopisShippingOption
					},
					dataType: 'html',
					success: function(modalContent) {
						if (modalContent.length > 0) {
							var $modalContent = $(modalContent);
							var $recsContent;
							if (pageContext.site === SiteConstants.SiteIds.SheplersUS) {
								$recsContent = $('#best_selling_products.accordion.product-recommendations').removeClass('accordion');
							} else if (pageContext.site === SiteConstants.SiteIds.BootBarnUS) {
								$recsContent = $('.recomm-prod-pdp-a2c-slot .accordion.product-recommendations').removeClass('accordion');
							} else {
								$recsContent = $('.main .accordion.product-recommendations').removeClass('accordion');
							}
							$recsContent.find('.accordion-head').removeClass('accordion-head');
							$recsContent.find('.accordion-body').removeClass('accordion-body');

							if ($recsContent.find('.slick-list').length > 0) {
								var $recommendationsContentHtml = $($recsContent[0].outerHTML);
								var $recommendationsSlides = $recommendationsContentHtml.find('.slide');
								var recommendationsSlidesHtml = '';
								$recommendationsSlides.each(function(index, element) {
									recommendationsSlidesHtml += $(this)[0].outerHTML;
								});
								$recommendationsContentHtml.find('.slides').html(recommendationsSlidesHtml);
								$recommendationsContentHtml.find('.slides').removeClass('slick-initialized slick-slider');
								$recommendationsContentHtml.find('.slide').removeClass('slick-slide slick-current slick-active slick-cloned').css('width', '');
								$modalContent.find('.product-recommendations-container').html($recommendationsContentHtml);
							}

							if (dataBuffer['OptionalMessage']) {
								var optionalMessageHtml = '<div class="add-to-cart-modal-optional-message">' + dataBuffer['OptionalMessage'] + '</div>';
								$modalContent.find('.added-message').append(optionalMessageHtml);
							}
							$modalContent.attr('bopis-shipping-option', bopisShippingOption);

							var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

							dialog.open({
								html: $modalContent,
								options: {
									dialogClass: 'add-to-cart-modal',
									draggable: false,
									resizable: false,
									width: 1090,
									open: function(event, ui) {
										dataBuffer['wrapperScrollTop'] = scrollTop;
    									dataBuffer['wrapperMarginTop'] = $container.css('margin-top');
										$container.attr('buffer-scrollTop', scrollTop);

    									$container.css('margin-top', parseFloat($container.css('margin-top')) - scrollTop);
    									document.body.scrollTop = document.documentElement.scrollTop = 0;
    									$html.addClass('scroll-lock');
										$('.add-to-cart-modal').wrap('<div class="add-to-cart-modal-wrapper"></div>');

										// add wrapper to close button 
										$('.add-to-cart-modal-wrapper').off('click.Dialog.CloseButtons').on('click.Dialog.CloseButtons', '.ui-dialog-titlebar-close, .btn.continue-shopping', function(event) {
											closeAddToCartModal();
										});
									
										// add 'show' class to overlay for nice animate in
										var $overlay = $('.add-to-cart-modal-wrapper').parent().find('.ui-widget-overlay');
										$overlay.addClass('add-to-cart-overlay');
										$overlay.addClass('show');

										$('.add-to-cart-modal-wrapper').off('click.Dialog.Close').on('click.Dialog.Close', function(event) {
											// only call this block if the ui-widget background is clicked
											if ($(event.target).closest('.add-to-cart-modal').length <= 0) {
												closeAddToCartModal();
											}
										});

										if (parseFloat($('.add-to-cart-modal').css('top')) < 0) {
											$('.add-to-cart-modal').css('top', '0');
										}

										// move title from main content to top of the modal
										var $addMessage = $('.add-to-cart-modal-content .main-content .added-message');
										if ($addMessage.length > 0) {
											$('.add-to-cart-modal-content').prepend($addMessage);
										}

										if (pageContext.site === SiteConstants.SiteIds.BootBarnUS) {
											var fulfillmentType = $('.bopis-add-to-cart-options-container').find('input[name="AddToCartOption"]:checked').val();
											var isBopis = fulfillmentType && (fulfillmentType == 'shiptostorepickup' || fulfillmentType == 'pickup');
											var bopisStoreName = '';
											var bopisStoreAddress = '';
											if (isBopis) {
												bopisStoreName = $('.bopis-add-to-cart-options-store-selector .selected-store').attr('store-name');
												bopisStoreAddress = $('.bopis-add-to-cart-options-store-selector .selected-store').attr('store-address');
												if (bopisStoreAddress && bopisStoreAddress.length > 0) {
													$('.modal-mini-cart-store-message-container').removeClass('d-none');
													$('.modal-mini-cart-store-message-container').find('.modal-mini-cart-store-details').html('<strong>' +bopisStoreName+ '</strong><br />' +bopisStoreAddress);
												}
											}
										}

										var $containers = $('.add-to-cart-modal').find('.accordion, .section-offers');
										if ($containers.length > 0) {
											$containers.each(function() {
												var $container = $(this);
												if ($container.find('.product-tile').length > 0) {
													var $containerHeader = $container.find('.accordion-head h5, .section-head h2').first();
													var $containerHeaderCheck = $containerHeader.find('span');
													if ($containerHeaderCheck.length > 0) {
														$containerHeader = $containerHeaderCheck;
													}
										
													if ($containerHeader.length > 0) {
														var dataObject = {
															promotion_id: 'addtocartmodal',
															promotion_name: 'Add to Cart Modal'
															
														}
										
														GoogleTagManagerClient.PushDataLayerForDataLayerType('ViewPromotion', dataObject, { Container: $container });
													}
												}
											});
										}

										$('.add-to-cart-modal-wrapper').addClass('show');
						            },
						            close: function(event, ui) {
						            	var $html = $('html');
								    	var $container = $('#wrapper');

								    	$html.removeClass('scroll-lock');

						            	if (dataBuffer['wrapperScrollTop'] > 0) {
							    			document.body.scrollTop = document.documentElement.scrollTop = dataBuffer['wrapperScrollTop'];
							    		}
										$container.css('margin-top', parseFloat(dataBuffer['wrapperMarginTop']));

										$('.add-to-cart-modal').unwrap();
						            }
								}
							});

							$('.add-to-cart-modal .added-message .count').text('Item');
						}
					}
				});
			}
			if ( $('.banner-cart-holder .btn-minicart').length > 0 ) {
				$.ajax({
					url : Urls.cartMiniCart,
					data : {desktop: '1'},
					dataType: 'html',
					success : function(data) {
						$('.banner-cart-holder .btn-minicart').remove();
						$('.banner-cart-holder').append(data);
					}
				});
				$.ajax({
					url : Urls.cartMiniCart,
					dataType: 'html',
					success : function(data) {
						var parentMiniCart = $('.top-banner-inner-links #mini-cart');
						if (parentMiniCart.length > 0) {
							$('.top-banner-inner-links #mini-cart a.mini-cart-link').remove();
							parentMiniCart.append(data);
						}
					}
				});
			}
			// do not close quickview if adding individual item that is part of product set
			// @TODO should notify the user some other way that the add action has completed successfully
			if (!$(this).hasClass('sub-product-item')) {
				dialog.close();
			}
			if(SitePreferences.ADD_TO_CART_MODAL != true){
				minicart.show(response);
			}
        }
    }.bind(this));

};

/**
 * @description Handler to handle the add all items to cart event
 */
var addAllToCart = function (e) {
    e.preventDefault();
    var $productForms = $('#product-set-list').find('form').toArray();
    TPromise.all(_.map($productForms, addItemToCart))
        .then(function (responses) {
            dialog.close();
            // show the final response only, which would include all the other items
            minicart.show(responses[responses.length - 1]);
        });
};

/**
 * @function
 * @description Binds the click event to a given target for the add-to-cart handling
 */
module.exports = function () {
    $('.add-to-cart[disabled]').attr('title', $('.availability-msg').text());
    $('.product-detail').on('click', '.add-to-cart:not([disabled]):not([loading])', addToCart);
	$(document).on('ProductDetailPage.Bopis.AddItemToCart', addItemToCart);
    $(document).on('ProductDetailPage.Bopis.AddToCart', addToCart);
	$('#add-all-to-cart').on('click', addAllToCart);
};
